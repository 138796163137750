import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import ExternalLink from "../components/external-link"

const Links = ({location}) => {

  return (
    <Layout pageTitle="Links" pathName={location.pathname}>
      <SEO title="Links" />
      <Content>
        <p><ExternalLink url="http://www.3sisecurity.com" title="3SI Security Systems"/></p>
        <p><ExternalLink url="http://www.cargonet.com" title="CargoNet"/></p>
        <p><ExternalLink url="http://www.dyplex.com" title="DYPLEX Communications Ltd."/></p>
        <p><ExternalLink url="https://www.dhs.gov/cargo-screening" title="Homeland Security - Cargo Screening"/></p>
        <p><ExternalLink url="http://www.ibc.ca/on/business/business-crime/cargo-theft" title="Insurance Bureau of Canada (IBC)"/></p>
        <p><ExternalLink url="http://www.marionso.com" title="Marion County Sheriff’s Office, FL"/></p>
        <p><ExternalLink url="https://www.nicb.org" title="National Insurance Crime Bureau"/></p>
        <p><ExternalLink url="https://www.securitases.com/" title="Securitas"/></p>
        <p><ExternalLink url="https://www.sensitech.com/en/supply-chain-security" title="Sensitech"/></p>
        <p><ExternalLink url="https://www.shadowfreight.com/" title="Shadow Freight Security"/></p>
        <p><ExternalLink url="http://www.swtsc.com" title="Southwest Transportation Security Council"/></p>
      </Content>
    </Layout>
  )
}

export default Links
